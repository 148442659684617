<template>
    <div>
        <b-navbar class="navbar header">
            <b-container class="col-12 container-fluid d-flex align-items-center justify-content-start topo">
                <img :src="cora" class="logo-cliente-sesi ml-1" alt="Logo" height="80">
                <div class="ml-2">
                    <h1>CORA</h1>
                    <h2 class="subtitulo">Assistente virtual do SESI</h2>
                </div>
            </b-container>
        </b-navbar>
        <div class="chat">
            <b-row class="linha" ref="chatContainer">
                <b-col lg="12" md="12" v-for="(dado, index) in dados" :key="index" >
                    <div v-if="dado.loading" >
                        <div class="d-flex espaco" >
                            <img :src="cora" class="img-cora-chat" alt="Cora">
                            <p class="texto-chat ml-1 loading-dots"></p>
                        </div>
                    </div>
                    <div class="d-flex flex-column flex-grow-1" v-if="dado.loading == false" >
                        <div class="d-flex espaco" >
                            <img :src="cora" class="img-cora-chat">
                            <p class="texto-chat ml-1" v-if="dado.link == false"><span v-html="dado.pergunta_msg"></span></p>
                            <p class="texto-chat texto-privacidade ml-1" v-if="dado.link">Antes de começarmos, vou te pedir que leia a nossa <a href="https://drive.google.com/file/d/1hC7ZVZGyHzJhAt7c-FCfSW2la9YyeujB/view" class="link" target="_blank" v-if="dado.link"><u>Política de privacidade</u></a> 📝.</p>
                        </div>
                        <div class="answer-options-div" v-if="dado.opcoes && dado.multivalorado == false" ref="answerOptionsDiv">
                            <button class="answer-option" @click="pegarProximaIteracao(opcao)" :class="{ 'lowlight': opcao.cor , 'back-btn': opcao.botao_inicio}" :style="opcao.botao_inicio ? 'margin: 0 auto; margin-top:20px;' : ''"  :disabled="opcao.desabilita_botao" v-for="opcao in dado.opcoes">
                              <span v-html="opcao.descricao_opcao"> </span>
                            </button>
                        </div>
                        <div class="answer-options-div" v-if="dado.opcoes && dado.multivalorado">
                            <button class="answer-option d-flex justify-content-start" @click="adicionarOpcoesEscolhidas(opcao,dado)" :key="opcaoIndex" v-for="(opcao,opcaoIndex) in dado.opcoes" :class="{ 'lowlight': opcao.cor_multivalorado}" :disabled="opcao.desabilita_botao_multivalorado">
                               <input type="checkbox" :checked="opcao.botao_multivalorado" :disabled="opcao.desabilita_botao_multivalorado" class="ml-2">
                               <label for="" class="form-label label-class ml-2"> <span v-html="opcao.descricao_opcao"> </span></label>
                            </button>
                        </div>
                        <div class="answer-options-div" v-if="dado.opcoes && dado.multivalorado">
                            <button  @click="pegarProximaIteracaoMultiploSelect()" :class="{ 'lowlight': dado.qtd_opcoes_multivaloradas == 0 || dado.qtd_opcoes_multivaloradas > dado.multivalorado || dado.desabilita_botao_enviar_multivalorado}" class="answer-option" :disabled="dado.qtd_opcoes_multivaloradas == 0 || dado.qtd_opcoes_multivaloradas > dado.multivalorado || dado.desabilita_botao_enviar_multivalorado" :title="'escolha de 1 a '+ dado.multivalorado+' opções' " style="margin: 0 auto; margin-top:10px">
                                Enviar
                            </button>
                        </div>
                    </div>
                    <div class="col-12 chat  d-flex justify-content-end"  v-if="dado.opcao_escolhida && !dado.multivalorado">
                        <p class="user-msg"><span v-html="dado.opcao_escolhida"></span></p>
                    </div>
                    <div class="col-12 chat-multivalorado  d-flex justify-content-end" v-for="opcao in dado.opcao_escolhida_multivalorada"  v-if="opcao_escolhida_multivalorada.length > 0 && dado.multivalorado">
                      <p class="user-msg"><span v-html="opcao"></span></p>
                    </div>
                </b-col>

            </b-row>
        </div>
    </div>
</template>

<script>

import { BNavbar, BContainer,BCol, BRow } from 'bootstrap-vue'
import cora from '@/assets/images/cora/Cora.png'
import { modalGenericFillableErrorWithReload } from '@/libs/sweetalerts'
import Swal from 'sweetalert2'

export default {

    components: {
        BNavbar,
        BContainer,
        BCol,
        BRow
    },

    data() {
        return {
            cora: cora,
            opcoes: [],
            opcao_clicada: false,
            opcao_escolhida: '',
            buscandoRequisicao:false,
            chave_usuario: '',
            chave_aplicacao: this.$route.params.aplicacao,
            resposta_opcao: '',
            dados: [],
            multiploSelect: [],
            opcao_escolhida_multivalorada: [],
            api_cora : process.env.VUE_APP_API_START_CORA,
            api_cora_resposta: process.env.VUE_APP_API_PROCESSAMENTO_CORA,
        }
    },

    mounted() {
        this.buscaChaveUsuario()
    },

    methods: {
        async buscaChaveUsuario(){
           let url = this.api_cora+this.chave_aplicacao

            await this.$http.get(url)
            .then(response => {
                this.dados = response.data.resposta_inicial_usuario
                this.chave_usuario = response.data.chave_usuario
                this.verificaLink()

                //Verifica caso tenha questões multivaloradas na primeira iteração quando pega a chave de usuario e adiciona atributo botao_multivalorado e qtd_opcoes_multivaloradas
                this.verificaQuestoesMultivaloradosPrimeiro()
            })
            .catch(error => {
              modalGenericFillableErrorWithReload(error.response.data.error)
            })

            // Aplica Delay pra carregar os pontinhos de depois a pergunta
            this.delayChat(this.dados)
        },


        delay(seconds) {
            const milliseconds = seconds * 1000;
            return new Promise(resolve => setTimeout(resolve, milliseconds))
        },

        async delayChat(mensagem){
            for (let i = 0; i < mensagem.length; i++) {
                this.$set(mensagem, i, { ...mensagem[i], loading: true })

                await this.delay(2)
                this.$set(mensagem, i, { ...mensagem[i], loading: false })
            }
        },

        async delayChatRespostas(mensagem){
            for (let i = 0; i < mensagem.length; i++) {
                if(mensagem[i].delayCarragamento == true){

                    this.$set(mensagem, i, { ...mensagem[i], loading: true })

                    this.$nextTick(() => {
                        this.scrollChatToBottom();
                    });

                    if(mensagem[i].delay_anterior){
                      await this.delay(mensagem[i].delay_anterior);
                      this.$set(mensagem, i, { ...mensagem[i], loading: false })
                    }else{
                      await this.delay(2)
                      this.$set(mensagem, i, { ...mensagem[i], loading: false })
                    }

                    mensagem[i].delayCarragamento = false
                }

            }

            // Para scroll focar na ultima div adicionada
            await this.delay(0.1);
            this.scrollChatToBottom();
        },

        async delayChatRespostasMultivaloradas(mensagem, delay = 2){
            for (let i = 0; i < mensagem.length; i++) {
                if(mensagem[i].delayCarragamento == true){

                    this.$set(mensagem, i, { ...mensagem[i], loading: true })

                    this.$nextTick(() => {
                        this.scrollChatToBottomMultivalor();
                    });
                    await this.delay(0.1);
                    this.scrollChatToBottomMultivalor();

                    if(mensagem[i].delay_anterior){
                        await this.delay(mensagem[i].delay_anterior);
                        this.$set(mensagem, i, { ...mensagem[i], loading: false })
                    }else{
                        await this.delay(delay)
                        this.$set(mensagem, i, { ...mensagem[i], loading: false })
                    }


                    mensagem[i].delayCarragamento = false
                }

            }

            // Para scroll focar na ultima div adicionada
            await this.delay(0.1);
            this.scrollUltimoMultivalorado();
        },

        async pegarProximaIteracao(opcao){
            this.opcao_clicada = true
            this.opcao_escolhida = opcao.descricao_opcao

            /*
                Pega ultimo elemento do array de dados e adiciona opcao_escolhida que é o que vai printar
                Para o usuario como resposta clicada
            */
            this.$set(this.dados, this.dados.length - 1, {
                ...this.dados[this.dados.length - 1],
                opcao_escolhida: this.opcao_escolhida,
            });

            this.delayChatRespostas(this.dados)
            this.verificaDesabilitaBotoes(opcao)

            let params = {
                chave_aplicacao: this.chave_aplicacao,
                chave_usuario: this.chave_usuario,
                resposta_opcao: opcao.id_opcao
            }

            let buscandoRequisicao = false

            setTimeout(() => {
                if(buscandoRequisicao){
                    this.modalCoraLoading('Houve um problema ao se conectar ao servidor. Tentando reconectar novamente...')
                }

            }, 5000)

            buscandoRequisicao = true

            //Busca próxima interação do chat de acordo com a reposta escolhida
            await this.$http.post(this.api_cora_resposta, params, {timeout: 60000})
            .then(response => {
                buscandoRequisicao = false
                this.fecharModalCora()
                if(response.data.length > 0){
                    // Adiciona Propriedade delayCarragamento para carregar a div após o delay com os três pontinhos da animação
                    const novasMensagens = response.data.map((res) => ({
                       ...res,
                        delayCarragamento: true,
                        qtd_opcoes_multivaloradas: 0,
                    }));
                    this.dados = [...this.dados, ...novasMensagens];
                    this.verificaBotaoInicio()
                    this.verificaLink()
                }
                this.delayChatRespostas(this.dados)
            })
            .catch(error => {
                buscandoRequisicao = false
                this.fecharModalCora()
                if(error.message == "Cannot read properties of null (reading 'status')" || !error.request){
                    modalGenericFillableErrorWithReload('Houve um problema com a sua conexão. Tente novamente mais tarde.')
                }else{
                    modalGenericFillableErrorWithReload('Servidor indisponível. Tente novamente mais tarde.')
                }


            })
        },

        async pegarProximaIteracaoMultiploSelect(){

           let resposta_opcao_multivalorada = this.multiploSelect.map((op) => op.id_opcao)
           this.opcao_escolhida_multivalorada = this.multiploSelect.map((op) => op.descricao_opcao)

           this.$set(this.dados, this.dados.length - 1, {
                ...this.dados[this.dados.length - 1],
                opcao_escolhida_multivalorada: this.opcao_escolhida_multivalorada,
            });

            this.verificaDesabilitaBotoesMultivalorados();

           let params = {
                chave_aplicacao: this.chave_aplicacao,
                chave_usuario: this.chave_usuario,
                resposta_opcao_multivalorada: resposta_opcao_multivalorada
            }

            let buscandoRequisicao = false

            setTimeout(() => {
                if(buscandoRequisicao){
                    this.modalCoraLoading('Houve um problema ao se conectar ao servidor. Tentando reconectar novamente...')
                }

            }, 5000)

            buscandoRequisicao = true

            //await this.$http.post(this.$api.buscaServicosCora, params)
            await this.$http.post(this.api_cora_resposta, params, {timeout: 60000})
            .then(response => {
                buscandoRequisicao = false
                this.fecharModalCora()
                if(response.data.length > 0){
                    // Adiciona Propriedade delayCarragamento para carregar a div após o delay com os três pontinhos da animação
                    const novasMensagens = response.data.map((res) => ({
                       ...res,
                        delayCarragamento: true
                    }));
                    this.dados = [...this.dados, ...novasMensagens];
                    this.verificaBotaoInicio()
                    this.verificaLink()

                    //Verifica caso tenha questões multivaloradas na primeira iteração quando pega a chave de usuario e adiciona atributo botao_multivalorado e qtd_opcoes_multivaloradas
                    this.verificaQuestoesMultivaloradosPrimeiro()
                }

                this.delayChatRespostasMultivaloradas(this.dados)
                 // Limpa multiploSelect apenas para esta instância específica
                this.$set(this, 'multiploSelect', []);
            })
            .catch((error) => {
                buscandoRequisicao = false
                this.fecharModalCora()
                if(error.message == "Cannot read properties of null (reading 'status')" || !error.request){
                    modalGenericFillableErrorWithReload('Houve um problema com a sua conexão. Tente novamente mais tarde.')
                }else{
                    modalGenericFillableErrorWithReload('Servidor indisponível. Tente novamente mais tarde.')
                }
            })
        },

        adicionarOpcoesEscolhidas(opcao,dado){
            opcao.botao_multivalorado = !opcao.botao_multivalorado;
            dado.desabilita_botao_enviar_multivalorado = false
            this.multiplaEscolhaSelect(opcao,dado)
        },

        scrollChatToBottom() {
            const lastElement = this.$refs.chatContainer.lastChild.lastElementChild;

            if (lastElement) {
                lastElement.scrollIntoView({ behavior: "smooth" });
            }

        },
        scrollUltimoMultivalorado(){
            let ultimoElemento  = this.$refs.chatContainer.children[this.$refs.chatContainer.children.length - 1];

            if(ultimoElemento.lastElementChild != null){
                ultimoElemento.lastElementChild.lastElementChild.scrollIntoView({ behavior: "smooth" });

                if(this.$refs.answerOptionsDiv){
                    const answerOptionsDiv = this.$refs.answerOptionsDiv[this.$refs.answerOptionsDiv.length - 1];
                     //Verifique se o elemento existe
                    if (answerOptionsDiv) {
                    // Role até o elemento usando scrollIntoView
                        answerOptionsDiv.scrollIntoView({ behavior: 'smooth' });
                    }
                }


            }
        },
        scrollChatToBottomMultivalor() {
            const lastElement = this.$refs.chatContainer.lastChild;

            if (lastElement) {
                lastElement.scrollIntoView({ behavior: "smooth" });
            }
        },


        verificaDesabilitaBotoes(opcao){
            //Percorre array de dados e desabilita botões que já foram clicados
            this.dados[this.dados.length - 1].opcoes.forEach((op) => {
                op.selecionado = op.id_opcao === opcao.id_opcao;
                op.selecionado_cor = op.id_opcao != opcao.id_opcao;
            });

            //Verifica se objeto tem opcoes e se tem desabilita botões que não foram clicados
            this.dados.forEach((dado) => {
                if (dado.opcoes) {
                    dado.opcoes.map((op) => {
                        op.desabilita_botao = op.selecionado || this.opcao_clicada;
                        op.cor = op.selecionado_cor;
                    });
                }
            });
        },

        verificaDesabilitaBotoesMultivalorados(){
            this.dados.map((dado) => {
                if (dado.opcoes) {
                    dado.opcoes.map((op) => {
                        if(op.multivalorado != false){
                            if(op.botao_multivalorado){
                                op.cor_multivalorado = op.botao_multivalorado ? false : true ;
                                op.desabilita_botao_multivalorado = true
                            }else{
                                op.cor_multivalorado = true;
                                op.desabilita_botao_multivalorado = true
                            }
                        }

                    });
                }
            });

            //Desabilita o botão de Enviar de questoes multivaloradas
            this.dados.map((dado) => {
                dado.desabilita_botao_enviar_multivalorado = true
            });
        },

        verificaBotaoInicio(){
           this.dados.map((dado) => {
                if (dado.opcoes) {
                    dado.opcoes.map((op) => {
                        op.botao_inicio = op.descricao_opcao === 'Voltar ao início' || op.descricao_opcao === '<b>Voltar ao início</b>';
                    });
                }
            });
        },

        verificaLink(){
            this.dados.map((dado) => {
               dado.link = dado.pergunta_msg == 'Antes de começarmos, vou te pedir que leia a nossa <2>. 📝' || dado.pergunta_msg == 'Antes de começarmos, vou te pedir que leia a nossa Política de Privacidade DASS 21. 📝' || dado.pergunta_msg =='Antes de começarmos, vou te pedir que leia a nossa <b>Política de Privacidade PHQ9</b>. 📝' ||  dado.pergunta_msg == 'Antes de começarmos, vou te pedir que leia a nossa <b>Política de Privacidade DASS 21</b>. 📝'

            });
        },

        multiplaEscolhaSelect(opcao,dado) {
            if (opcao.botao_multivalorado) {              // Adiciona a opção ao array se estiver marcada checkbox
                this.multiploSelect.push(opcao);
            } else {
                // Remove a opção do array se estiver desmarcada
                const index = this.multiploSelect.indexOf(opcao);
                if (index !== -1) {
                    this.multiploSelect.splice(index, 1);
                }

                if(this.multiploSelect.length  <= 1){
                    dado.desabilita_botao_enviar_multivalorado = true
                }
            }

            dado.qtd_opcoes_multivaloradas = this.multiploSelect.length
        },

        verificaQuestoesMultivaloradosPrimeiro(){
            this.dados.map((dado) => {
                if (dado.opcoes && dado.multivalorado != false) {
                    dado.qtd_opcoes_multivaloradas = 0
                    dado.desabilita_botao_enviar_multivalorado = true
                    dado.opcoes.map((op) => {
                        if(op.botao_multivalorado != true){
                            op.botao_multivalorado = false
                        }
                    });
                }
            });
        },

        modalCoraLoading(texto){
            let timerInterval;
            Swal.fire({
                width: 550,
                imageUrl: require('@/assets/custom-icons/cora-icons/warning-red-icon.png'),
                imageWidth: 80,
                imageHeight: 80,
                title: 'Erro na conexão',
                allowOutsideClick: false,
                html: `<span class="">${texto}</span>`,
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading();
                },
                willClose: () => {
                    clearInterval(timerInterval);
                }
            })
        },
        fecharModalCora(){
            Swal.close()
        },
    }

}

</script>

<style>

body {
    background-color:#e5eef7 !important ;
}
.back-btn {
    background: none !important;
    border: 2px solid rgba(0,32,97,.5019607843) !important;
    color: #002060 !important;
    font-weight: 600;
    margin-top: 1em;
}
.answer-option-last{
    width: 50%;
    max-width: 50%;
    margin: 1% auto;
    position: relative;
}
.link{
    color: #000000;
    font-weight: bold;
}
.linha{
    margin-right: 0px !important;
}
.answer-options-div{
    max-width: 40%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 3.5rem;
    margin-bottom: 14px;

}
.label-class{
    color: #fff;
}
.answer-option{
    width: 45%;
    max-width: 45%;
    min-height: 30px;
    padding: 1% 2%;
    background-color: #002060;
    color: #fff;
    border-radius: 20px;
    box-shadow: 0 4px 8px #0000001f;
    font-size: 13px;
    justify-content: center;
    align-items: center;
    margin: 1% 2%;
    font-family: Montserrat,serif;
    font-weight: 300;
    cursor: pointer;
    border: none;
}
.chat {
    flex: 1;
    background-color: #e5eef7;
    padding: clamp(20px,2vw,30px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    position: relative;
}
.chat-multivalorado{
    flex: 1;
    background-color: #e5eef7;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    position: relative;
}
.espaco{
    margin-bottom: 18px !important;
}
.chat p {
    height: -moz-fit-content;
    height: fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 40%;
    padding: 8px 16px;
    border-radius: 5px;
    font-weight: 300;
    line-height: 1.5;
    text-align: justify;
    overflow-wrap: break-word;
    color: #000000;
}
.user-msg {
    margin-left: auto;
    background: linear-gradient(45deg,#1265BB .32%,#4EA4FE 100%);
    color: #fff !important;
    margin-bottom: 14px;
    font-size: 16px;
}

.lowlight {
    background-color: #00206180 !important;
    cursor: auto;
}
.header{
    padding: 0 !important;
    color: #fff;
}
.topo{
    height: 112px;
    background: linear-gradient(45deg,#1265BB .32%,#4EA4FE 100%);
    display: flex;
    align-items: center;
    color: #fff;
}
.topo h1 {
    font-size: 2.1rem;
    font-weight: 500;
    color: #fff;

}
.subtitulo {
    font-size: 1.5rem;
    font-weight: 200;
    color: #fff;
    font-family: Montserrat,serif;

}
.img-cora-chat{
    width: 48px;
    height: 48px;
}
.texto-chat{
    height: -moz-fit-content;
    height: fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 40%;
    padding: 8px 16px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;
    text-align: justify;
    overflow-wrap: break-word;
    background-color: #fff;
}

@keyframes dotsAnimation {
    0%, 20% {
        content: ' .';
    }
    40%, 60%{
        content: ' ..';
    }
     80%,100% {
        content: ' ...';
    }

}

.loading-dots::after {
    content: ' ....';
    animation: dotsAnimation 1s infinite;
    display: inline-block;
}


@media (max-width: 768px) {
    .answer-options-div{
        max-width: 100%;
        margin-left: 0;
    }
    .answer-option{
        width: 100%;
        max-width: 100%;
        margin: 1% 0;
    }

    .chat p {
        max-width: 100%;
    }

}

</style>
